import {FC, useEffect, useState} from 'react';
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import {useAction} from "../../../../store/hooks/useAction";
import './betEngineTickets.css';
import Tickets from "../components/Tickets";
import {getStoragedCurrentOperator, getSessionStoragedCurrentOperator} from "../../auth";
import {useDebounce} from "../../../../_metronic/helpers";

const BetEngineTickets: FC = () => {
    const [paginationPage,setPaginationPage] = useState(1);
    const {getBetengineTicketsData} = useAction();
    const {betEngineTicketsData, loading} = useTypedSelector((state) => state.betengineTicketsReducer);
    const [searchUserID, setSearchUserID] = useState<string>('');
    const debouncedSearchTerm = useDebounce(searchUserID, 1500);
    const handleSearchUserId = (userID: string) => {
        const cleanedUserID = userID.trim().replace(/\s+/g, '');
        setSearchUserID(cleanedUserID);
    };
    let operatorID = getStoragedCurrentOperator();
    const sessionOperator = getSessionStoragedCurrentOperator();
    if (sessionOperator) {
        operatorID = sessionOperator;
    }
    const handleRefreshData = () => {
        getBetengineTicketsData(10,paginationPage,operatorID);
    }
    useEffect(() => {
        if (!debouncedSearchTerm) {
            getBetengineTicketsData(10, paginationPage, operatorID);
        }
    }, [paginationPage, operatorID]);

    useEffect(() => {
        const encodedUserID = debouncedSearchTerm
            ? encodeURIComponent(debouncedSearchTerm)
            : undefined;

        getBetengineTicketsData(10, paginationPage, operatorID, encodedUserID);
    }, [debouncedSearchTerm, paginationPage, operatorID]);



    return(
        <Tickets
            betEngineTicketsData={betEngineTicketsData}
            setPaginationPage={setPaginationPage}
            paginationPage={paginationPage}
            handleRefreshData={handleRefreshData}
            setSearchUserID={handleSearchUserId}
        />
    );
}


export {BetEngineTickets};
