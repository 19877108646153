import {Dispatch} from "redux";
import {LiveLogsAction, LiveLogsActionTypes} from "../../types/composer/liveLogsTypes";
import axios from "axios";


export const getLiveLogsData = (operator: string, player_id?: string): any => {
    let apiURL = `/api/composer/betcard_logs`;

    if (operator) {
        apiURL += `?operator=${operator}`;
    }

    if (player_id) {
        apiURL += operator ? `&search=${player_id}` : `?search=${player_id}`;
    }

    return async (dispatch: Dispatch<LiveLogsAction>) => {
        try {
            dispatch({ type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA });

            const response = await axios.get(apiURL, { withCredentials: false });

            dispatch({
                type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: LiveLogsActionTypes.FETCH_LIVE_LOGS_DATA_ERROR,
                payload: 'Something went wrong, check Live Log action controller'
            });
        }
    };
};
